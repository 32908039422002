<template>
  <div>
    <f-page-header :title="title" :prevLinks="prevLinks" />

    <div class="system-warning" v-if="editing">
      {{ $t('labels.settings.dataWarning') }}
    </div>

    <f-container topBottom>
      <f-user-category-form />
    </f-container>
  </div>
</template>

<script>
import FUserCategoryForm from '../../../components/views/settings/data/UserCategoryForm.vue';

export default {
  components: {
    FUserCategoryForm,
  },
  data: () => ({
    editing: false,
  }),
  computed: {
    prevLinks() {
      return [
        {
          title: this.$t('labels.settings'),
          route: {
            name: 'settings',
          },
        },
        {
          title: this.$t('labels.userCategories'),
          route: {
            name: 'settings-user-category-list',
          },
        },
      ];
    },
    title() {
      return this.$t('labels.userCategory');
    },
  },
  created() {
    if (this.$route.name == 'settings-user-category-edit') {
      this.editing = true;

      this.get();
    } else {
      this.$store.commit('system/loading', false);
    }
  },
  methods: {
    async get() {
      const result = await this.$store.dispatch(
        'setting/getUserCategory',
        this.$route.params.id
      );
      if (!result) {
        this.$router.push({
          name: 'settings-user-category-list',
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.system-warning {
  padding: 30px;
  margin: 30px 40px -18px 40px;
  max-width: 1600px;
  height: 100%;
  position: relative;
  background-color: red;
  line-height: 1.8rem;
  font-size: 1.2rem;
  color: black;
}
</style>
